<template>
  <v-container fluid>
    <v-alert
            dense
            :type="alert.type"
            timeout="5000"
            v-if="alert.message">
        {{ alert.message }}
    </v-alert>
    <v-tabs
      v-model="tab"
      fixed-tabs
      background-color="blue darken-2"
      dark
    >
      <v-tab key="manual">
        Nginx Manual Block Firewall
      </v-tab>
      <v-tab key="auto" v-if="enable_auto_http_flood">
        Nginx Auto Block Firewall
      </v-tab>
      <v-tab key="auto_firewall">
        Auto Block Firewall
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="manual">
        <v-card>
          <v-card-title>FIREWALL NGINX HTTP FLOOD</v-card-title>
          <v-card-text>
            <p class="mx-1 text-h6">Banned: <span class="red--text">{{ips.length}}</span></p>
            <div class="pa-1 ma-1">
              <p class="red--text">*** PLEASE NOTE NGINX WILL UPDATED EVERY 1MIN NOT REAL TIME. WHEN YOU PUT IP YOU NEED TO WAIT 1MIN OR LESS TO MAKE NGINX UPDATE BLACKLIST***</p>
              <v-row>
                <v-text-field
                  v-model="new_ip"
                  label="IP"
                  class="mx-1 col-4"
                  clearable
                />
                <v-btn
                  color="red"
                  class="mx-1"
                  @click="doAddIP()"
                  :loading="loading_manual_add"
                  :disabled="loading_manual_add || new_ip.length < 1"
                  :dark="loading_manual_add"
                >
                  Block It
                </v-btn>
              </v-row>
            </div>
          </v-card-text>
          <v-card-text>
            <v-btn
              color="green"
              class="mx-1"
              @click="doFetchManual()"
              :loading="loading_manual"
              :disabled="loading_manual"
              :dark="loading_manual"
            >
              Reload
            </v-btn>
            <v-text-field
                v-model="ip"
                label="Search by ip"
                class="mx-1"
                clearable
            />
          </v-card-text>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-center blue--text text-h6">
                    IP
                  </th>
                  <th class="text-center blue--text text-h6">
                    Remove
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item,index) in filted_list"
                    :key="index"
                >
                  <td class="text-center blue--text text-h6">{{ item }}</td>
                  <td class="text-center blue--text text-h6">
                    <v-btn
                      color="red"
                      size="small"
                      dark
                      :loading="loading_manual_remove === index+1"
                      :disabled="loading_manual_remove === index+1"
                      @click="doRemoveIP(index+1)"
                    >
                      Remove
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item key="auto_firewall">
        <v-card>
          <v-card-title>SYSTEM FIREWALL</v-card-title>
          <v-card-text>
            <p class="mx-1 text-h6">Banned: <span class="red--text">{{ips_firewall.length}}</span></p>
            <div class="pa-1 ma-1">
              <p class="red--text">*** DELAY BLOCKED IP LIST: <strong>30s</strong> ***</p>
              <!-- <v-row>
                <v-text-field
                  v-model="new_ip"
                  label="IP"
                  class="mx-1 col-4"
                  clearable
                />
                <v-btn
                  color="red"
                  class="mx-1"
                  @click="doAddIP()"
                  :loading="loading_manual_add"
                  :disabled="loading_manual_add || new_ip.length < 1"
                  :dark="loading_manual_add"
                >
                  Block It
                </v-btn>
              </v-row> -->
            </div>
          </v-card-text>
          <v-card-text>
            <v-btn
              color="green"
              class="mx-1"
              @click="doFetchSystemFirewall()"
              :loading="loading_firewall"
              :disabled="loading_firewall"
              :dark="loading_firewall"
            >
              Reload
            </v-btn>
            <v-text-field
                v-model="ip"
                label="Search by ip"
                class="mx-1"
                clearable
            />
          </v-card-text>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-center blue--text text-h6">
                    IP
                  </th>
                  <th class="text-center blue--text text-h6">
                    Remove
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item,index) in filted_list"
                    :key="index"
                >
                  <td class="text-center blue--text text-h6">{{ item }}</td>
                  <td class="text-center blue--text text-h6">
                    <v-btn
                      color="red"
                      size="small"
                      dark
                      :loading="loading_firewall_remove === item"
                      :disabled="loading_firewall_remove === item"
                      @click="doRemoveIPSystem(item)"
                    >
                      Remove
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item key="auto" v-if="enable_auto_http_flood">
        <v-card>
          <v-card-title>FIREWALL NGINX HTTP FLOOD</v-card-title>
          <v-card-text>
            <p class="mx-1 text-h6">Current Banned: <span class="red--text">{{data.current_banned}}</span></p>
            <p class="mx-1 text-h6">Total Banned: <span class="red--text">{{data.total_banned}}</span></p>
          </v-card-text>
          <v-card-text>
            <v-btn
              color="green"
              class="mx-1"
              @click="doFetch()"
              :loading="loading"
              :disabled="loading"
              :dark="loading"
            >
              Reload
            </v-btn>
            <v-text-field
                v-model="ip"
                label="Search by ip"
                class="mx-1"
                clearable
            />
          </v-card-text>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-center blue--text text-h6">
                    IP
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item,index) in banned"
                    :key="index"
                >
                  <td>{{ item }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import {fetchData} from "../helpers";
import { mapState, mapActions } from 'vuex';
import http from '../helpers/http';
export default {
  name: "Firewall",
  data(){
    return {
      tab: "manual",
      new_ip: '',
      ip: null,
      ip_firewall: null,
      ips:[],
      ips_firewall:[],
      data:{
        current_banned: 0,
        total_banned: 0,
        ips: []
      },
      loading: false,
      loading_manual: false,
      loading_manual_add: false,
      loading_manual_remove: null,
      loading_firewall: false,
      loading_firewall_remove: null,
      enable_auto_http_flood: false,
    }
  },
  computed:{
    ...mapState({
        alert: state => state.alert
    }),
    banned(){
      return this.data.ips.filter((item)=>{
        if(this.ip !== null && this.ip.length > 0){
          return item.includes(this.ip);
        }
      });
    },
    filted_list(){
      const list_data = this.tab === 0 ? this.ips:this.ips_firewall;
      return list_data.filter((item)=>{
        if(this.ip !== null && this.ip.length > 0){
          return item.includes(this.ip);
        }
        return item;
      });
    }
  },
  methods:{
    ...mapActions('alert', ['success', 'error']),
    doParseIpList(ip_raw){
      return ip_raw.split('\n');
    },
    doFetch(){
      this.loading = true;
      fetchData(`${this.$API}/api/firewall`)
        .then((json)=>{
          this.data = json;
        }).finally(()=>this.loading = false);
    },
    doFetchSystemFirewall(){
      this.loading_firewall = true;
      fetchData(`${this.$API}/api/firewall/blocked-ips`)
        .then((data)=>{
          this.ips_firewall = this.doParseIpList(data);
        }).finally(()=>
        {
          this.loading_firewall = false;
          setTimeout(()=>this.doFetchSystemFirewall(), 30000);
        });
    },
    doFetchManual(){
      this.loading_manual = true;
      fetchData(`${this.$API}/api/block-ips`)
        .then((json)=>{
          this.ips = json.data;
        }).finally(()=>this.loading_manual = false);
    },
    doAddIP(){
      const app = this;
      const ip_valid = app.doCheckIP(app.new_ip);
      if(ip_valid !== true){
        app.error("IP ADDRESS INVALID");
        return;
      }
      app.loading_manual_add = true;
      fetchData(`${app.$API}/api/block-ips`, {ip: app.new_ip})
        .then((json)=>{
          if(json.success){
            app.success(json.message);
            app.ips.push(app.new_ip);
            app.new_ip = '';
          }else{
            app.error(json.message);
          }
        }).finally(()=>app.loading_manual_add = false);
    },
    doCheckIP(ipAddress) {
      const ipv4Pattern = /^(\d{1,3}\.){3}\d{1,3}$/;
      const ipv6Pattern = /^(?:[0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/
      if (ipv4Pattern.test(ipAddress)) {
        const parts = ipAddress.split('.');
        for (let i = 0; i < 4; i++) {
          const part = parseInt(parts[i], 10);
          if (part < 0 || part > 255 || isNaN(part)) {
            return false;
          }
        }
        return true;
      } else if (ipv6Pattern.test(ipAddress)) {
        return true;
      }
      return false;
    },
    doRemoveIP(index){
      const app = this;
      app.loading_manual_remove = index;
      fetchData(`${app.$API}/api/block-ips`, {index})
        .then((json)=>{
          if(json.success){
            app.success(json.message);
            app.ips.splice(index-1,1);
          }else{
            app.error(json.message);
          }
        }).finally(()=>app.loading_manual_remove = null);
    },
    doRemoveIPSystem(ip){
      const app = this;
      app.loading_firewall_remove = ip;
      return http.get(`${this.$API}/api/firewall/blocked-ips`, {
        headers: {
          'Remove-Block-IP': ip,
        },
      }).then((res)=>{
        if(res.data === 'OK'){
          app.success("Blocked Ip Removed");
          const index = app.ips_firewall.indexOf(ip);
          app.ips_firewall.splice(index,1);
        }else{
          app.error("Error when remove ip");
        }
      }).finally(()=>app.loading_firewall_remove = null);
    },
  },
  mounted() {
    //this.doFetch();
    this.doFetchManual();
    this.doFetchSystemFirewall();
  }
}
</script>

<style scoped>

</style>