<template>
    <div>
        <v-container fluid>
            <v-alert
                dense
                :type="alert.type"
                timeout="5000"
                v-if="alert.message && !isPick">
                {{ alert.message }}
            </v-alert>
            <v-toolbar
                color="blue"
                dark
            >
                <v-toolbar-title>
                    <v-breadcrumbs :items="Path" v-if="Path.length > 0">
                        <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            @click="viewFolderIndex(item)""
                        >
                           {{ item }}
                        </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        label="Search"
                        placeholder="Type to search..."
                        solo
                        hide-details
                        prepend-icon="mdi-magnify"
                        single-line
                        dense
                        clearable
                    />
            </v-toolbar>
            <div class="ma-2 pa-2" v-if="!isPick">
                <v-tabs>
                    <v-tab>Create XAPK</v-tab>
                    <v-tab>Download APKM</v-tab>
                    <v-tab-item>
                        <CreateXAPK ref="xapk" />
                    </v-tab-item>

                    <v-tab-item>
                        <DownloadAPKM />
                    </v-tab-item>
                </v-tabs>
            </div>
            <v-row dense class="ma-2 pa-2">
                <v-col
                v-for="f in Fs"
                :key="f.delete_hash"
                cols="auto"
                >
                <v-card>
                    <v-card-title>
                        <v-img
                            :src="f.ext ? f.ext === 'apk' ? '/images/files/apk.png':'/images/files/file.png' : '/images/files/folder.png'"
                            class="white--text align-end mx-1"
                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                            height="32px"
                            width="32px"
                            max-width="32px"
                            max-height="32px"
                        />
                        <span v-text="f.file_name" />
                    </v-card-title>
                    <v-card-text>
                        <template v-if="!f.directory">
                            <div v-text="`Hash: ${f.hash}`" class="mx-1" />
                            <div v-text="`File Size: ${parseSize(f.size)}`" class="mx-1" />
                        </template>
                    </v-card-text>
                        <v-card-actions>
                            <v-btn icon v-if="!f.directory && !isPick && f.ext === 'apk'" @click="PickAPK(f)">
                                <v-icon>mdi-package-check</v-icon>
                            </v-btn>
                            <v-btn icon v-if="!f.directory && !isPick && f.ext === 'obb'" @click="PickOBB(f)">
                                <v-icon>mdi-package-check</v-icon>
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn icon v-if="!f.directory && isPick" @click="Pick(f)">
                                <v-icon>mdi-eye-arrow-right</v-icon>
                            </v-btn>

                            <v-btn icon v-if="f.directory" @click="viewFolder(f.file_name)">
                                <v-icon>mdi-eye-arrow-right</v-icon>
                            </v-btn>
                            <v-btn icon v-if="f.delete_hash" @click="askDelete(f.delete_hash, f.directory)" class="red--text">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-card-actions>
                </v-card>
                </v-col>
            </v-row>
            <Loading :status="Overlay" />
        </v-container>
    </div>
</template>

<script>
//import Alert from '../helpers/notice';
import Loading from '../components/Loading.vue';
import Loader from '../components/Loader.vue';
import {mapActions, mapState} from "vuex";
import {fetchData, parseSize} from "../helpers";
import DownloadAPKM from '../components/DownloadAPKM.vue';
import CreateXAPK from '../components/CreateXAPK.vue';
export default {
  name: "FileServe",
    components: {
        Loading,
        Loader, 
        DownloadAPKM,
        CreateXAPK,
    },
    props:{
        root: {
            type: String,
            default: "/"
        },
    },
    data(){
        return {
            Overlay: false,
            search: "",
            fs:[],
            top: '/',
            isPick: false,
        }
    },
    created() {
        if (this.$listeners.onPick) {
            this.isPick = true;
        }
        this.top = this.root;
    },
    computed: {
        ...mapState('auth', ['status']),
        ...mapState({
            alert: state => state.alert
        }),
        Path() {
            const result = this.top.split('/');
            return ["Root"].concat(result.filter(r=>r !== ""));
        },
        Fs(){
            return this.fs.filter(f=>f.file_name.indexOf(this.search) > 0 || (f.hash && f.hash === this.search.toString().toLowerCase()) || this.search==="" || this.search === null || this.search === undefined)
        }
    },
    methods:{
        parseSize,
        ...mapActions('auth', ['login', 'logout']),
        ...mapActions('alert', ['success', 'error']),
        PickOBB(f){
            if(this.$refs.xapk){
                this.$refs.xapk.PickOBB(this.top, f);
            }
        },
        PickAPK(f){
            if(this.$refs.xapk){
                this.$refs.xapk.PickAPK(this.top, f);
            }
        },
        Pick(f){
            f.file_name = `${this.top}/${f.file_name}`;
            this.$emit('onPick', f);
        },
        async askDelete(delete_hash, is_directory){
            const answer = await this.$confirm(`Do you really want to delete this ? This data can't restore !`,{ title: 'Warning' });
            if(answer){
                this.Overlay = true;
                const cmd = is_directory ? "d":"f";
                return fetchData(`${this.$API}/api/fm/r/${cmd}/${delete_hash}`, undefined, "DELETE").then(()=>{},(err)=>{
                    if(err === 401){
                        this.logout();
                    }else{
                        this.error('SERVER ERROR !!!');
                    }
                }).finally(()=>{
                    this.Overlay = false;
                    this.fetchFs();
                });
            }
        },
        viewFolderIndex(item){
            let index = this.Path.indexOf(item);
            let path = '';
            const paths = this.top.split('/');
            for(let i = 0; i <= index; i++){
                path += paths[i];
                if (i < index) path += '/';
            }
            this.top = path;
            this.fetchFs();
        },
        viewFolder(path){
            if(this.top === '/'){
                this.top = `/${path}`;
            }else{
                this.top = `${this.top}/${path}`;
            }
            this.fetchFs();
        },
        fetchFs(){
            this.Overlay = true;
            const filter = this.isPick ? "&filter=apk,xapk,obb":"";
            return fetchData(`${this.$API}/api/fm/fs?path=${this.top}${filter}`).then((json)=>{
                this.fs = json.files;
                if(json.top) this.top = json.top;
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error('SERVER ERROR !!!');
                }
            }).finally(()=>this.Overlay = false);
        },
    },
    mounted() {
        this.fetchFs();
    }
}
</script>